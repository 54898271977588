import "./member.css";
import React, {useEffect, useState} from "react";
import {
    CurrencyFormat,
    dateTimeFormat,
    ServerDate,
} from "../../services/misc.functions";
import _ from "lodash";
import {env, transTypes} from "../../constants/global.vars";
import {ApiService} from "../../services/http.service";
import {SubmitHandler, useForm} from "react-hook-form";
import {isMobile} from "../../App";

export default function MemberTransactions() {
    const today = ServerDate();

    const [loading, setLoading] = useState(true);
    const [dataList, setDataList] = useState<any[]>([]);
    const [page, setPage] = useState<number>(0);
    const [filter, setFilter] = useState<any>({
        start_date: today.toISOString().slice(0, 10),
        end_date: null,
        trans_type: null,
    });

    const {register, handleSubmit, watch} = useForm<any>();

    useEffect(() => {
        loadData();
    }, [filter]);

    useEffect(() => {
        const subscription = watch((value, {name, type}) => {
            if (type === "change") {
                handleSubmit(onSubmit)();
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, handleSubmit]);

    const loadData = async (force: any = null) => {
        const api = new ApiService();
        setLoading(true);
        if (page === 0 || force === 0) {
            setDataList([]);
        }

        let payload: any = {
            metadata: {
                page: force ? force : page,
            },
            start_date: filter.start_date,
            end_date: filter.end_date,
            trans_type: filter.trans_type,
        };

        return await api
            .start("post", env.accounting_api + "/member/api/list-transaction/", payload, true)
            .then((res: any) => {
                setLoading(false);
                setPage(page + 1);

                if (res && res.status) {
                    setDataList([...dataList, ...res.data]);
                }

                if (res && res.metadata) {
                    let id_datalist_loadMore: any = document.getElementById("id_datalist_loadMore");

                    if (res.metadata.total_page <= page + 1) {
                        id_datalist_loadMore.style.display = "none";
                    } else {
                        id_datalist_loadMore.style.display = "block";
                    }
                }
            });
    };

    const onSubmit: SubmitHandler<any> = (data: any) => {
        const startDate = new Date(today);
        startDate.setDate(startDate.getDate() - Number(data.date));
        setPage(0);
        setDataList([]);
        var _filter: any = {
            start_date: startDate.toISOString().slice(0, 10) + "T00:00:00",
            trans_type: data.trans_type !== "-1" ? data.trans_type : null,
        };
        if (Number(data.date) === 0 || Number(data.date) === 1) {
            _filter.end_date = startDate.toISOString().slice(0, 10) + "T23:59:59";
        }
        setFilter(_filter);
    };

    const loadMore = (e: any) => {
        e.target.disabled = true;
        loadData().finally(() => {
            e.target.disabled = false;
        });
    };

    // let lastBalanceTRY: number = Number(auth.member.balance_debit);
    // let lastBalancePoint: number = Number(auth.member.balance_point);

    return (
        <>
            <h1>Hesap Hareketleri</h1>
            <div className="page-widget">
                <div className="p-2">
                    <form className="filter-form" onSubmit={handleSubmit(onSubmit)}>
                        <select className="form-control" {...register<any>("trans_type")}>
                            <option value={-1}>Tümü</option>
                            {Object.entries(transTypes).map((item: any, key: number) => {
                                return (
                                    <option key={`trans-types-${key}`} value={item[0]}>
                                        {item[1]}
                                    </option>
                                );
                            })}
                        </select>

                        <select className="form-control" {...register<any>("date")}>
                            <option value={0} selected>
                                Bugün
                            </option>

                            <option value={7}>Son 7 Gün</option>

                            <option value={30}>Son 30 Gün</option>

                            <option value={90}>Son 3 Ay</option>

                            <option value={180}>Son 6 Ay</option>

                            <option value={365}>Son 1 Yıl</option>

                            <option value={9999}>Tüm Zamanlar</option>
                        </select>
                    </form>
                    <div className={"table-responsive"}>
                        <table className="table table-borderless betting-table">
                            <thead>
                            <tr>
                                <th style={{width: 80}}>İşlem Zamanı</th>
                                <th>İşlem Türü</th>
                                {!isMobile && <th>Açıklama</th>}
                                <th style={{width: 110}} className="text-center">
                                    Tutar
                                </th>
                                {/* <th style={{ width: 130 }} className="text-center">
                    Bakiye
                  </th> */}
                            </tr>
                            </thead>
                            <tbody>
                            {_.orderBy(dataList, ["id"], ["desc"]).map((item: any, key: number) => {
                                // if (key === 0) {
                                //   lastBalanceTRY = Number(auth.member.balance_debit);
                                //   lastBalancePoint = Number(auth.member.balance_point);
                                // } else {
                                //   if (dataList[key - 1].io_type === 0) {
                                //     lastBalanceTRY += parseFloat(dataList[key - 1].amount);
                                //   } else {
                                //     lastBalanceTRY -= parseFloat(dataList[key - 1].amount);
                                //   }
                                // }

                                return (
                                    <React.Fragment key={`transaction-${key}`}>
                                        <tr>
                                            <th className="seperator"></th>
                                        </tr>
                                        <tr>
                                            <td>
                                                {dateTimeFormat(item.transaction_date).split(' ')[0]}
                                                <br/>
                                                {dateTimeFormat(item.transaction_date).split(' ')[1]}
                                            </td>
                                            <td>
                                                {item.trans_type_display}
                                                {isMobile && <> <br/>
                                                    <div style={{maxWidth: "175px"}} className={"text-truncate"}>
                                                        {item.description}
                                                    </div>
                                                </>
                                                }
                                            </td>
                                            {!isMobile && <td>{item.description}</td>}
                                            <td className="fw-semibold text-center">
                          <span className={item.io_type === 1 ? "text-success" : "text-danger"}>
                            {CurrencyFormat(item.amount)} {item.unit === 1 ? "NP" : "TL"}
                          </span>
                                            </td>
                                            {/* <td className="fw-semibold text-end">{CurrencyFormat(lastBalanceTRY)}₺</td> */}
                                        </tr>
                                    </React.Fragment>
                                );
                            })}
                            </tbody>
                        </table>
                    </div>
                    {dataList &&
                        dataList.length === 0 &&
                        (loading ? (
                            <>
                                <div id="id_loaderAnimation" className="loader"></div>
                                <div className="text-center py-2">Yükleniyor lütfen bekleyiniz..</div>
                            </>
                        ) : (
                            <>
                                <div className="text-center py-2">Listelenecek hiç kayıt yok.</div>
                            </>
                        ))}

                    <div className="d-flex text-center justify-content-center mt-3 ">
                        <button id="id_datalist_loadMore" onClick={loadMore} type="button"
                                className="btn btn-outline-primary">
                            Devamını Yükle
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}
