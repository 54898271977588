import { Subject } from "rxjs";
import { Storage } from "./localstorege.service";
import _ from "lodash";
import { combination, permutation, Permutation } from "js-combinatorics";
import { isAndroid, isIOS, isMacOs, isMobile, isWindows } from "react-device-detect";
import { modalServiceSubject } from "./modal.service";
import { env } from "../constants/global.vars";
import { ApiService } from "./http.service";
import { DailyRaces } from "../store/bulletin.store";
import paths from "../paths";

export const tjkBetslipStore: any = [];

export const tjkBetSlipOptions: any = {
  betSlipMultiply: 1,
  bettingType: null,
  bettingSubType: null,

  hippodrome: null,
  selectedRun: null,
  usePointBalance: false,
  inProgress: false,
  errorMessage: null,
  complete: false,
  maxAmount: 1,
  system: false,
  shareCoupon: false,
};

export let bettingTab: any = { title: "" };
export const bettingTabSubject = new Subject();

export const tjkCalculateProfitOptions: any = {
  selectedHorses: {},
};
export const agfBulletinData: any = {
  selectedWager: null,
  agfLabel: null,
};

export const betSlipActionSubject = new Subject();

export let mobileBetSlipActions: any = { isOpen: false };
export const mobileBetSlipSubject = new Subject();

export let updateBetCount: any = { status: true };

export const tjkSortedBetTypes = [50, 53, 56, 63];
export const tjkOrderedBetTypes = [8, 9, 10, 47, 51, 52, 54, 61, 62];
export const tjkSingleLegBetTypes = [11, 48];
export const tjkCompleteBettingTypes = [8, 50, 51, 53, 54, 56, 61, 63];

export function addToTJKBetSlip(column: any, run: any, horse: any, action: string = "toggle") {
  // console.log(run);
  // console.log(horse);
  // console.log(tjkBetSlipOptions);
  if (tjkBetSlipOptions.bettingType == null) {
    modalServiceSubject.next({
      title: "Uyarı",
      content: "Lütfen bahis türü seçin.",
      timeout: 3000,
      confirm: {
        cancel: {
          label: "Tamam",
        },
      },
    });
    return;
  }
  if (tjkBetSlipOptions.hippodrome.status !== true){
    return;
  }

  console.log(column, run.no, horse !== "s" ? horse.no : "s");
  updateBetCount.status = true;
  tjkBetSlipOptions.errorMessage = null;
  tjkBetSlipOptions.column = column;

  if (tjkBetSlipOptions.inProgress === true) {
    modalServiceSubject.next({
      title: "Uyarı",
      content: "Bahisleriniz işleniyor. Lütfen bekleyiniz.",
      timeout: 3000,
      confirm: {
        cancel: {
          label: "Tamam",
        },
      },
    });
    return;
  }

  if (horse !== "s" && horse.run_status === false) return;


  if(tjkBetSlipOptions.bettingType.id === 1881){

    let allOf:any = tjkBetslipStore.filter((el:any)=>el.hippodrome === Number(tjkBetSlipOptions.hippodrome.id) &&
      el.column === column &&
      el.run === Number(run.no) &&
      el.bettingType === tjkBetSlipOptions.bettingType.id &&
      el.bettingSubType === tjkBetSlipOptions.bettingSubType?.id)

    allOf.forEach((el:any)=>{

      let horseIndex = tjkBetslipStore.findIndex(
        (el: any) =>
          el.hippodrome === Number(tjkBetSlipOptions.hippodrome.id) &&
          el.column === column &&
          el.run === Number(run.no) &&
          el.bettingType === tjkBetSlipOptions.bettingType.id &&
          el.bettingSubType === tjkBetSlipOptions.bettingSubType?.id
      );

      tjkBetslipStore.splice(horseIndex, 1);
    });
  }

  let horseIndex = isAdded(column, run, horse);
  let subjectAction: string = "";

  if (horseIndex === false && (action === "toggle" || action === "add")) {
    tjkBetslipStore.push({
      hippodrome: Number(tjkBetSlipOptions.hippodrome.id),
      bettingType: tjkBetSlipOptions.bettingType.id,
      bettingSubType: tjkBetSlipOptions.bettingSubType.id,
      column: column,
      run: Number(run.no),
      horse: horse !== "s" ? String(horse.no) : "s",
    });
    subjectAction = "add";
    try {
      // @ts-ignore
      fbq("track", "AddToCart");
    } catch (e) {
      console.log(e);
    }
  } else if (horseIndex !== false && (action === "toggle" || action === "remove")) {
    subjectAction = "remove";
    tjkBetslipStore.splice(horseIndex, 1);
  }





  if (action === "toggle") {
    // console.log(tjkBetslipStore);
    betSlipActionSubject.next({ action: subjectAction, column: column, run: run, horse: horse });
  }
  Storage.set("betSlip", tjkBetslipStore);
}

export function removeFromBetSlip(column: any, run: any, horse: any) {
  let horseIndex = tjkBetslipStore.findIndex(
    (el: any) =>
      el.horse === String(horse.no) &&
      el.column === Number(column) &&
      el.run === Number(run.no) &&
      el.hippodrome === Number(tjkBetSlipOptions.hippodrome.id) &&
      el.bettingType === tjkBetSlipOptions.bettingType.id &&
      el.bettingSubType === tjkBetSlipOptions.bettingSubType.id
  );
  if (horseIndex !== -1) {
    tjkBetslipStore.splice(horseIndex, 1);
    Storage.set("betSlip", tjkBetslipStore);
    updateBetCount.status = true;
    betSlipActionSubject.next({ action: "remove", column: column, run: run, horse: horse });
  }
}

export function removeHorseFromBetSlip(hippodrome: any, run: any, horse: any) {
  console.log("removeHorseFromBetSlip", hippodrome, run, horse);

  let items: any = tjkBetslipStore.filter(
    (el: any) => el.horse === String(horse) && el.run === Number(run) && el.hippodrome === Number(hippodrome)
  );

  if (items.length > 0) {
    for (let item of items) {
      let itemIndex = tjkBetslipStore.findIndex((i: any) => i === item);
      tjkBetslipStore.splice(itemIndex, 1);
    }
    Storage.set("betSlip", tjkBetslipStore);
    betSlipActionSubject.next({ action: "remove" });
  }
}

export function restoreBetSlip() {
  let betSlip = Storage.get("betSlip");
  if (betSlip) {
    for (let item of betSlip) {
      let hip: any = DailyRaces.find((h: any) => Number(h.id) === Number(item.hippodrome));
      if (hip) {
        let race: any = hip.runs.find((r: any) => Number(r.no) === Number(item.run));
        if (race) {
          let horse: any = race.horses.find((hor: any) => String(hor.no) === String(item.horse));

          if (String(item.horse) === "s" || (horse && horse.run_status === true)) {
            let isExist: any = tjkBetslipStore.find(
              (el: any) =>
                el.hippodrome === item.hippodrome &&
                el.bettingType === item.bettingType &&
                el.bettingSubType === item.bettingSubType &&
                el.column === item.column &&
                el.run === item.run &&
                el.horse === item.horse
            );
            if (!isExist) {
              tjkBetslipStore.push(item);
            }
          }
        }
      }
    }

    setTimeout(() => {
      betSlipActionSubject.next({ action: "restore" });
    }, 300);
  }
}

export function isAdded(column: any, run: any, horse: any) {
  let horseIndex = tjkBetslipStore.findIndex(
    (el: any) =>
      el.hippodrome === Number(tjkBetSlipOptions.hippodrome.id) &&
      String(el.horse) === (horse !== "s" ? String(horse.no) : "s") &&
      el.column === column &&
      el.run === Number(run.no) &&
      el.bettingType === tjkBetSlipOptions.bettingType.id &&
      el.bettingSubType === tjkBetSlipOptions.bettingSubType?.id
  );
  return horseIndex === -1 ? false : horseIndex;
}

export function isAddedAll(column: any, run: any) {
  let status =
    tjkBetslipStore.filter(
      (el: any) =>
        el.hippodrome === Number(tjkBetSlipOptions.hippodrome.id) &&
        el.column === column &&
        el.horse !== "s" &&
        el.run === Number(run.no) &&
        el.bettingType === tjkBetSlipOptions.bettingType.id &&
        el.bettingSubType === tjkBetSlipOptions.bettingSubType.id
    ).length === run.horses.filter((h: any) => h.run_status === true).length
      ? true
      : false;
  return status;
}

export function addAllToBetSlip(column: any, run: any, action: string = "add") {
  if (action === "add") {
    run.horses.forEach((horse: any) => {
      addToTJKBetSlip(column, run, horse, "add");
    });
  } else {
    run.horses.forEach((horse: any) => {
      addToTJKBetSlip(column, run, horse, "remove");
    });
  }
  betSlipActionSubject.next(true);
}

export function setComplete() {
  tjkBetSlipOptions.complete = !tjkBetSlipOptions.complete;
  updateBetCount.status = true;
  betSlipActionSubject.next({ action: "complete" });
}

function getC(ix: any, sysCols: any) {
  let cR = 1;
  for (let c of sysCols) {
    cR = 1;
    for (let j of _.range(Number(c.s), Number(c.h) + 1)) {
      let bs = ix * j;
      if (bs * tjkBetSlipOptions.bettingType.price <= Number(tjkBetSlipOptions.maxAmount)) {
        // console.log(j, (bs * tjkBetSlipOptions.bettingType.price))
        cR = j;
      }
    }
    ix *= cR;
  }
  return ix;
}

function getCombinations(chars: any, n: number = 3) {
  var result: any = [];
  var f = function (prefix: any, chars: any) {
    for (var i = 0; i < chars.length; i++) {
      if (prefix.length === n - 1) {
        result.push(prefix.concat(chars[i]));
      }
      f(prefix.concat(chars[i]), chars.slice(i + 1));
    }
  };

  f([], chars);

  return result;
}

export function setTjkUsePointBalance(value: boolean) {
  if (tjkBetSlipOptions.errorMessage === "Bakiyeniz yetersiz.") {
    tjkBetSlipOptions.errorMessage = null;
  }
  tjkBetSlipOptions.usePointBalance = value;
  betSlipActionSubject.next(true);
}

export function setTjkShareCoupon(value: boolean) {
  tjkBetSlipOptions.shareCoupon = value;
  betSlipActionSubject.next(true);
}

export function getRunHorses(hippodrome: any, run: any) {
  let runs = DailyRaces.find((h: any) => Number(h.id) === Number(hippodrome))?.runs;
  if (runs && runs.length > 0) {
    return runs.find((r: any) => Number(r.no) === Number(run)).horses.filter((h: any) => h.run_status === true);
  }

  return [];
}

export function getBetCount2() {
  // console.log("CALC BET COUNT")
  // console.log("tjkBetslipStore", tjkBetslipStore)
  let columnCount = 1;
  let betCount = 1;
  let slipItem = tjkBetslipStore.filter((el: any) => {
    if (
      el.hippodrome === Number(tjkBetSlipOptions.hippodrome.id) &&
      el.bettingType === Number(tjkBetSlipOptions.bettingType.id) &&
      el.bettingSubType === Number(tjkBetSlipOptions.bettingSubType.id)
    ) {
      return el;
    }
  });
  let uniqueColumn = [...Array.from(new Set(slipItem.map((i: any) => i.column)))];
  let minBetCount = 1;
  let minAvailableBetCount: any = 1;
  let maxAvailableBetCount: any = 1;

  let bankoCols: any = [];
  let allCols: any = [];
  let sysCols: any = [];

  for (let c of uniqueColumn) {
    let cItems = slipItem
      .filter((i: any) => i.column === c)
      .map((el: any) =>
        el.horse === "s"
          ? {
              horse: "s",
              column: el.column,
              horseCount: getRunHorses(el.hippodrome, el.run).length,
            }
          : { horse: el.horse, column: el.column, horseCount: getRunHorses(el.hippodrome, el.run).length }
      );
    if (cItems.find((e: any) => e.horse === "s")) {
      columnCount *= cItems.horseCount;
      minBetCount *= cItems.length;
      allCols.push({ c: c, s: cItems.length });
      sysCols.push({ c: c, s: cItems.length, h: cItems[0].horseCount });
    } else {
      columnCount *= cItems.length;
      minBetCount *= cItems.length;
      allCols.push({ c: c, s: cItems.length });
      bankoCols.push({ c: c, s: cItems.length });
    }
  }

  console.log(allCols);
  console.log(bankoCols);

  betCount = columnCount;

  if (
    tjkBetSlipOptions.bettingType &&
    tjkBetSlipOptions.bettingType.column === uniqueColumn.length &&
    sysCols.length > 0
  ) {
    let ix = 1;
    for (let c of bankoCols) {
      ix *= c.s;
    }
    minAvailableBetCount = getC(ix, sysCols);
    maxAvailableBetCount = getC(ix, sysCols.reverse());

    console.log("minAvailableBetCount, maxAvailableBetCount", minAvailableBetCount, maxAvailableBetCount);

    let betCounts = _.orderBy([minAvailableBetCount, maxAvailableBetCount], [], ["asc"]);
    if (sysCols.length > 0) {
      tjkBetSlipOptions.system = true;
      tjkBetSlipOptions.systemMaxMin = betCounts;
      columnCount = maxAvailableBetCount;
    } else {
      tjkBetSlipOptions.system = false;
      tjkBetSlipOptions.systemMaxMin = null;
    }
  } else {
    tjkBetSlipOptions.system = false;
    tjkBetSlipOptions.systemMaxMin = null;
  }

  // Plase & Ganyan
  if (tjkBetSlipOptions.bettingType.id === 4) {
    betCount *= 2;
  }

  // Sıralı ve Normal ikili, Üçlü, Dörtlü, Beşli Bahisler
  if (tjkOrderedBetTypes.includes(tjkBetSlipOptions.bettingType.id)) {
    let legs: any = [];
    for (let c of uniqueColumn) {
      let cItems = slipItem.filter((i: any) => i.column === c).map((el: any) => el.horse);
      legs.push(cItems);
    }

    if (legs.length === 2) {
      let combinations = legs[0]
        .flatMap((d: any) =>
          legs[1].map((v: any) => {
            if (d !== v) {
              return d + "-" + v;
            }
            return null;
          })
        )
        .filter((e: any) => e !== null);

      columnCount = combinations.length;

      // İkili kombinasyonlarda aynı kombinasyonun tersi varsa çıkar
      if (
        tjkBetSlipOptions.bettingType.id === 10 ||
        tjkBetSlipOptions.bettingType.id === 47 ||
        tjkBetSlipOptions.bettingType.id === 50
      ) {
        columnCount -=
          combinations.filter((e: any) => combinations.includes(e.split("-")[1] + "-" + e.split("-")[0])).length / 2;
      }

      if (tjkBetSlipOptions.complete === true) {
        columnCount *= 2;
      }
    } else if (legs.length === 3) {
      let permu: any = [[0, 1, 2]];
      if (tjkBetSlipOptions.complete === true) {
        permu = new Permutation([0, 1, 2], 3);
      }

      let allCombinations: any = [];
      for (let _r of [...permu]) {
        let combinations = legs[_r[0]]
          .flatMap((d: any) =>
            legs[_r[1]].map((v: any) => {
              if (d !== v) {
                return d + "-" + v;
              }
              return null;
            })
          )
          .filter((e: any) => e !== null);
        combinations = combinations
          .flatMap((d: any) =>
            legs[_r[2]].map((v: any) => {
              if (d.split("-").indexOf(String(v)) === -1) {
                return d + "-" + v;
              }
              return null;
            })
          )
          .filter((e: any) => e !== null);
        allCombinations.push(...combinations);
      }
      columnCount = Array.from(new Set(allCombinations)).length;
    } else if (legs.length === 4) {
      let permu: any = [[0, 1, 2, 3]];
      if (tjkBetSlipOptions.complete === true) {
        permu = new Permutation([0, 1, 2, 3], 4);
      }

      let allCombinations: any = [];
      for (let _r of [...permu]) {
        let combinations = legs[_r[0]]
          .flatMap((d: any) =>
            legs[_r[1]].map((v: any) => {
              if (d !== String(v)) {
                return d + "-" + v;
              }
              return null;
            })
          )
          .flatMap((d: any) =>
            legs[_r[2]].map((v: any) => {
              if (d && d.split("-").indexOf(String(v)) === -1) {
                return d + "-" + v;
              }
              return null;
            })
          )
          .flatMap((d: any) =>
            legs[_r[3]].map((v: any) => {
              if (d && d.split("-").indexOf(String(v)) === -1) {
                return d + "-" + v;
              }
              return null;
            })
          )
          .filter((e: any) => e !== null);

        allCombinations.push(...combinations);
      }
      columnCount = Array.from(new Set(allCombinations)).length;
    } else if (legs.length === 5) {
      let permu: any = [[0, 1, 2, 3, 4]];
      if (tjkBetSlipOptions.complete === true) {
        permu = new Permutation([0, 1, 2, 3, 4], 5);
      }

      let allCombinations: any = [];
      for (let _r of [...permu]) {
        let combinations = legs[_r[0]]
          .flatMap((d: any) =>
            legs[_r[1]].map((v: any) => {
              if (d !== String(v)) {
                return d + "-" + v;
              }
              return null;
            })
          )
          .flatMap((d: any) =>
            legs[_r[2]].map((v: any) => {
              if (d && d.split("-").indexOf(String(v)) === -1) {
                return d + "-" + v;
              }
              return null;
            })
          )
          .flatMap((d: any) =>
            legs[_r[3]].map((v: any) => {
              if (d && d.split("-").indexOf(String(v)) === -1) {
                return d + "-" + v;
              }
              return null;
            })
          )
          .flatMap((d: any) =>
            legs[_r[4]].map((v: any) => {
              if (d && d.split("-").indexOf(String(v)) === -1) {
                return d + "-" + v;
              }
              return null;
            })
          )
          .filter((e: any) => e !== null);

        allCombinations.push(...combinations);
      }
      columnCount = Array.from(new Set(allCombinations)).length;
    } else {
      columnCount = 0;
    }

    betCount = columnCount;
  }

  // Sıralı Virgüllü
  if (tjkSortedBetTypes.includes(tjkBetSlipOptions.bettingType.id)) {
    let permu: any = [Array.from(Array(tjkBetSlipOptions.bettingType.horseCount).keys())];
    if (tjkBetSlipOptions.complete === true) {
      permu = new Permutation(
        Array.from(Array(tjkBetSlipOptions.bettingType.horseCount).keys()),
        tjkBetSlipOptions.bettingType.horseCount
      );
    }

    betCount = 0;
    for (let _r of [...permu]) {
      betCount += Number(combination(slipItem.length, tjkBetSlipOptions.bettingType.horseCount));
    }
  }

  // Tek Kolonlu
  if (tjkSingleLegBetTypes.includes(tjkBetSlipOptions.bettingType.id)) {
    betCount = Number(combination(slipItem.length, 2));
  }

  return tjkBetSlipOptions.bettingType && tjkBetSlipOptions.bettingType.column === uniqueColumn.length ? betCount : 0;
}

let memoBetCount: any = 0;

export function getBetCount() {
  if (updateBetCount.status) {
    memoBetCount = getBetCount2();
    updateBetCount.status = false;
  }
  return memoBetCount;
}

export function setTjkBetSlipMaxAmount(amount: any) {
  updateBetCount.status = true;
  if (amount !== "") {
    tjkBetSlipOptions.maxAmount = Number(amount) > 0 ? Number(amount) : 1;
  } else {
    tjkBetSlipOptions.maxAmount = "";
  }
  betSlipActionSubject.next(true);
}

export function getCouponLegs() {
  // fixme gereksiz iki defa çalışıyor
  let slipItem = _.orderBy(
    tjkBetslipStore.filter((el: any) => {
      if (
        el.hippodrome === Number(tjkBetSlipOptions.hippodrome.id) &&
        el.bettingType === Number(tjkBetSlipOptions.bettingType.id) &&
        el.bettingSubType === Number(tjkBetSlipOptions.bettingSubType.id)
      ) {
        return el;
      }
    }),
    ["column"]
  );
  let uniqueColumn = [...Array.from(new Set(slipItem.map((i: any) => i.column)))];
  let legs = [];
  for (let c of uniqueColumn) {
    let cItems = slipItem.filter((i: any) => i.column === c).map((el: any) => (el.horse !== "s" ? el.horse : "s"));
    legs.push(cItems);
  }
  return legs;
}

export function getLegsSelections() {
  let legs: any = [];

  let tabs: any = [];
  for (
    let i = Number(tjkBetSlipOptions.bettingSubType.id);
    i < Number(tjkBetSlipOptions.bettingSubType.id) + tjkBetSlipOptions.bettingType.column;
    i += 1
  ) {
    tabs.push(i);
  }
  const runs = tjkBetSlipOptions.hippodrome.runs.filter((run: any) => tabs.includes(Number(run.no)));
  let slipItem: any;
  tabs.forEach((tab: any, i: number) => {
    // console.log(i)
    if (tjkSortedBetTypes.includes(tjkBetSlipOptions.bettingType.id)) {
      slipItem = tjkBetslipStore.filter((el: any) => {
        if (
          el.hippodrome === Number(tjkBetSlipOptions.hippodrome.id) &&
          el.bettingType === Number(tjkBetSlipOptions.bettingType.id) &&
          el.bettingSubType === Number(tjkBetSlipOptions.bettingSubType.id) &&
          el.column === i + 1
        ) {
          return el;
        }
      });
    } else {
      slipItem = _.orderBy(
        tjkBetslipStore.filter((el: any) => {
          if (
            el.hippodrome === Number(tjkBetSlipOptions.hippodrome.id) &&
            el.bettingType === Number(tjkBetSlipOptions.bettingType.id) &&
            el.bettingSubType === Number(tjkBetSlipOptions.bettingSubType.id) &&
            el.column === i + 1
          ) {
            return el;
          }
        }),
        [(a: any) => Number(a.horse === "s" ? -1 : a.horse)]
      );
    }

    legs.push(slipItem.map((el: any) => (el.horse === "s" ? "S" : el.horse)));
  });

  return legs;
}

export async function PlayTjkCoupon(save: boolean = false) {
  // console.log("PlayTjkCoupon")
  // console.log("tjkBetSlipOptions", tjkBetSlipOptions)

  setTimeout(() => {
    modalServiceSubject.next({
      case: "info",
      title: "Kuponunuz Oynanıyor",
      content:
        '<div class="text-center"><div class="loader"></div><p>Kuponunuz oynanmak üzere TJK sistemine iletildi. TJK sisteminden yanıt gelene kadar lütfen bekleyiniz.</p></div>',
      confirm: null,
      backdropCloseDisabled: true,
    });
  }, 100);

  updateBetCount.status = true;

  let betCount = getBetCount();
  let price;
  if (tjkBetSlipOptions.system) {
    price = tjkBetSlipOptions.maxAmount;
  } else {
    price = Number(tjkBetSlipOptions.betSlipMultiply) * Number(betCount) * Number(tjkBetSlipOptions.bettingType.price);
  }
  // let price = Number(tjkBetSlipOptions.betSlipMultiply) * Number(betCount) * Number(tjkBetSlipOptions.bettingType.price)

  let couponData: any = {
    betType: tjkBetSlipOptions.bettingType.id,
    cardId: tjkBetSlipOptions.hippodrome.card_id,
    race: tjkBetSlipOptions.bettingSubType.label.split("-")[0],
    multiplier: tjkBetSlipOptions.betSlipMultiply,
    betCount: betCount,
    system: tjkBetSlipOptions.system,
    price: price.toFixed(2),
    legs: getCouponLegs(),
    poolUnit: Math.round(Number(tjkBetSlipOptions.bettingType.price) * Number(100)),
    systemAmount: 5,
  };

  if (tjkBetSlipOptions.bettingType.id === 1881) {

    let fixOddHorse = tjkBetSlipOptions.hippodrome.runs.filter((run: any)=>
      run.no === tjkBetSlipOptions.bettingSubType.label.split("-")[0]
    );
    let fobDetail:any = fixOddHorse[0].event_odds[Number(couponData.legs)]
    couponData.fob = tjkBetSlipOptions.bettingType.id === 1881 ? true : false
    couponData.oddRatio = fobDetail?.win_odds;
    couponData.oddId = fobDetail?.id;
  }

  let orderUrl = env.betting_api + "/games/api/tjk/place";
  let api = new ApiService();
  return await api
    .start(
      "post",
      orderUrl,
      {
        coupon: couponData,
        unit: tjkBetSlipOptions.usePointBalance ? 2 : 0,
        complete: tjkBetSlipOptions.complete,
        save: save,
        tipster: tjkBetSlipOptions.shareCoupon,
        device_os: isAndroid ? "Android" : isIOS ? "iOS" : isMacOs ? "Macintosh" : isWindows ? "Windows" : "Linux",
        browser_type: "YENI-WEB",
        device_type: isMobile ? "Mobile" : "Desktop",
      },
      true,
      60000
    )
    .then((result: any) => {
      return result;
    })
    .catch((error: any) => {
      modalServiceSubject.next({
        title: "Teknik hata",
        content: error + "<p>Kuponunuzun oynanıp oynanmadığını TJK kuponlarım sayfasından kontrol ediniz.</p>",
        confirm: {
          cancel: {
            label: "TJK Kuponlarım",
            action: () => {
              window.location.href = paths.memberTickets.url;
            },
          },
        },
      });
      return null;
    })
    .finally(() => {
      setTimeout(() => {
        modalServiceSubject.next(false);
      }, 5000);
    });
}

export function clearTjkBetSlip() {
  let slipItem = tjkBetslipStore.filter((el: any) => {
    if (
      el.hippodrome === Number(tjkBetSlipOptions.hippodrome.id) &&
      el.bettingType === Number(tjkBetSlipOptions.bettingType.id) &&
      el.bettingSubType === Number(tjkBetSlipOptions.bettingSubType.id)
    ) {
      return el;
    }
  });
  for (let item of slipItem) {
    tjkBetslipStore.splice(tjkBetslipStore.indexOf(item), 1);
  }

  tjkBetSlipOptions.errorMessage = null;
  tjkBetSlipOptions.betSlipMultiply = 1;
  tjkBetSlipOptions.usePointBalance = false;
  tjkBetSlipOptions.shareCoupon = false;
  tjkBetSlipOptions.complete = false;

  updateBetCount.status = true;

  Storage.set("betSlip", tjkBetslipStore);
  betSlipActionSubject.next({ action: "clear", data: null });
}

export function clearTjkBetSlipOptions() {
  tjkBetSlipOptions.errorMessage = null;
  tjkBetSlipOptions.betSlipMultiply = 1;
  tjkBetSlipOptions.usePointBalance = false;
  tjkBetSlipOptions.shareCoupon = false;
  tjkBetSlipOptions.bettingSubType = null;
  tjkBetSlipOptions.bettingType = null;
  updateBetCount.status = true;
  tjkBetSlipOptions.complete = false;
  // console.log(tjkBetSlipOptions);

  Storage.set("betSlip", tjkBetslipStore);
  betSlipActionSubject.next({ action: "clear", data: null });

  agfBulletinData.selectedWager = null;
  agfBulletinData.agfLabel = null;
}

export function setTjkBetSlipMultiply(multiply: any) {
  tjkBetSlipOptions.errorMessage = null;
  tjkBetSlipOptions.betSlipMultiply = multiply;
  betSlipActionSubject.next(true);
}
