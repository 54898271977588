import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {availableHippodromes} from "../../store/bulletin.store";
import {ApiService} from "../../services/http.service";
import {changedJokeyUrlPrefix} from "../../constants/global.vars";


const tabs = [
    {value: 0, label: "Koşmayan Atlar"},
    {value: 1, label: "Jokey Değişikliği"},
];


export function HorseStats() {
    const params = useParams();

    const [selectedTab, setSelectedTab] = useState<any>(params?.tab ? tabs[Number(params?.tab)] : tabs[0]);
    const [hippodrome, setHippodrome] = useState<any>(null);
    const [horses, setHorses] = useState<any>([]);
    const [hippodromeList, setHippodromeList] = useState<any>([]);
    const [jokeyChanges, setJockeyChanges] = useState<any>([]);


    const handleSelect = (e: any) => {
        if (e.target.id) {
            setSelectedTab(tabs[e.target.id]);

        }
        // if(e.target.id === 0){
        //     getNotRunningHorses();
        // }else{
        //     getData();
        // }
    };

    useEffect(() => {

        if (hippodrome == null) {
            setHippodrome(availableHippodromes[0]);
        }
        if (selectedTab.value === 0) {
            getNotRunningHorses();
        }else{
            getData();
        }
    }, [hippodrome]);

    const getNotRunningHorses = ()=>{

        var notRunningHorses = [];

        if (hippodrome) {
            if (hippodrome.runs) {
                for (const run of hippodrome.runs) {
                    for (const horse of run.horses) {
                        if (!horse.run_status) {
                            horse.run_no = run.no;
                            notRunningHorses.push(horse);
                        }
                    }
                }
            }
            setHorses(notRunningHorses);

        }
    }

    const getData = () => {

        // let cachedData = Storage.get(`changedJokeys`);
        // if (!cachedData) {
        let api = new ApiService()
        api.start('get', changedJokeyUrlPrefix, null, false)
            .then((res: any) => {
                if (res.status) {
                    setHippodromeList(res.data);
                    setTimeout(() => {
                        getHippodromejokeys();
                    }, 1000);

                } else {
                    setHippodromeList([]);
                }
            })
        // } else {
        //     setData(cachedData);
        // }
    }

    const getHippodromejokeys = () => {

        var selectedHippodromeJokey;
        for (const hippodromeListElement of hippodromeList) {
            if (hippodromeListElement.keyword === hippodrome.keyword) {
                selectedHippodromeJokey = hippodromeListElement;
            }
        }
        if (selectedHippodromeJokey) {
            setJockeyChanges(selectedHippodromeJokey.jockey_changes);
        }
    }

    const getHorseName = (runNo: any, horseNo: any) => {
        try {
            var selectedRun = hippodrome.runs.filter((el: any) => Number(el.no) === Number(runNo));
            var selectedHorse = selectedRun[0].horseMap[horseNo];
            return selectedHorse.name;
        } catch (e: any) {
            return horseNo;
        }
    }



    return (<>
        <div className="bettingtype-options p-3">
            <div className="row">

                <div className={`col-lg-4 mt-2 mt-sm-0`}>
                    <label>HİPODROM</label>
                    <select onChange={(e: any) => {
                        setHippodrome(availableHippodromes.filter((n: any) => n.keyword == e.target.value)[0]);
                    }} value={hippodrome?.keyword}>
                        {availableHippodromes.map((hippodrome: any, keyHippodrome: number) => {
                            return (<option key={`keyHippodrome-${keyHippodrome}`}
                                            value={hippodrome.keyword}>{hippodrome.hippodrome}</option>)
                        })}
                    </select>

                </div>
                <div className={`col-lg-4 mt-sm-0`}>

                </div>
            </div>
        </div>
        <div className="page-widget">


            <svg style={{display: "none"}}>
                <defs>
                    <path id="tabshape" d="M80,60C34,53.5,64.417,0,0,0v60H80z"></path>
                </defs>
            </svg>


            <ul className="nav nav-tabs">
                {tabs.map((tab: any, index: number) => {
                    return (
                        <li
                            key={`home-tabs-${index}`}
                            className={`nav-item ${selectedTab.value === tab.value ? "active" : ""}`}
                            onClick={handleSelect}
                        >
                            <svg className="left" viewBox="0 0 80 60" preserveAspectRatio="none">
                                <use xlinkHref="#tabshape"></use>
                            </svg>
                            <a className="nav-link" id={`${index}`}>
                                {tab.label.toUpperCase()}
                            </a>
                            <svg viewBox="0 0 80 60" preserveAspectRatio="none">
                                <use xlinkHref="#tabshape"></use>
                            </svg>
                        </li>
                    );
                })}
            </ul>

            <div className={`p-3`}>

                {selectedTab.value === 0 && <>
                    {horses.length > 0 && <table className={"w-100 table table-primary table-borderless table-striped"}>
                        <thead>
                        <tr>
                            <th className={"text-center"} style={{width: 75}}>Koşu</th>
                            <th>At</th>
                        </tr>
                        </thead>
                        <tbody>

                        {horses.map((horse: any, index: any) => {
                            return <tr key={"not-running-horse" + index}>
                                <td className={"text-center"}>{horse.run_no}</td>
                                <td>{horse.name}</td>
                            </tr>
                        })}

                        </tbody>
                    </table>}

                    {horses.length === 0 &&
                        <p className="text-center p-4">Bu hipodromda koşmayan at yoktur.</p>
                    }
                </>}

                {selectedTab.value === 1 && <>
                    {jokeyChanges.length > 0 &&
                        <table className={"w-100 table table-primary table-borderless table-striped"}>
                            <thead>
                            <tr>
                                <th className={"text-center"} style={{width: 75}}>Koşu</th>
                                <th>At</th>
                                <th>Jokey</th>
                            </tr>
                            </thead>
                            <tbody>

                            {jokeyChanges.map((jockey: any, index: any) => {
                                return <tr key={`changes-jokey-${index}`}>
                                    <td className={"text-center"}>{jockey.run_no}</td>
                                    <td>{getHorseName(jockey.run_no, jockey.horse_no)}</td>
                                    <td>{jockey.jockey_old}</td>
                                </tr>
                            })}
                            </tbody>
                        </table>}
                    {jokeyChanges.length === 0 &&
                        <p className="text-center p-4">Bu hipodromda jokey değişikliği yoktur.</p>
                    }
                </>}

            </div>

        </div>
    </>)
}
